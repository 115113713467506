import { graphql, useStaticQuery } from 'gatsby'

import { getLocalizedData } from '../../utils/localeURL'
const localizedConfigData = getLocalizedData(
  process.env.LOCALE,
  process.env.PROJECT
)

export const useHeaderData = () => {
  const {
    orchard: { menu },
  } = useStaticQuery(
    graphql`
        {
            orchard {
                menu(where: {alias: {alias_ends_with: "Menu"}}) {
                alias {
                  alias
                }
                menuItemsList {
                    menuItems {
                        ... on Orchard_ContentMenuItem {
                                contentMenuItemTheme
                                contentMenuItemIcon
                                render
                                menuItemsList {
                                    menuItems {
                                      ... on Orchard_ContentMenuItem {
                                        contentMenuItemDescription
                                        contentMenuItemIcon
                                        render
                                      }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    `
  )

  var languageMenu = menu.filter(
    (m) => m.alias.alias.indexOf(localizedConfigData.isoCode) === 0
  )

  return languageMenu && languageMenu.length
    ? languageMenu[0].menuItemsList
    : menu[0].menuItemsList
}